<template>
  <v-card
    outlined
    :to="{ name: 'Challenge', params: { slug: challenge.slug }}"
    class="mb-4"
  >
    <v-img
      :src="(challenge.image) ? challenge.image : ''"
      height="200px"
      class="px-3"
    >
      <v-row class="mb-10">
        <v-col>
          <div class="headline">
            {{ challenge.name }}
          </div>
        </v-col>
      </v-row>
      <v-row class="align-end pt-5">
        <v-col class="text-right">
          <div class="title">
            {{ challenge.points_available }}
          </div>
          <div>
            points
          </div>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    challenge: {
      type: Object,
      required: true,
    }
  },
}
</script>
