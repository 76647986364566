<template>
  <div class="challenges">
    <h1 class="page-title">
      Challenges
    </h1>
    <v-card>
      <v-card-text>
        <Loading
          v-if="loading"
          :loading="loading"
          message="Loading Challenges"
        />
        <div v-else-if="challengeList.length">
          Showing {{ challengeList.length }} challenges
          <v-row>
            <v-col
              v-for="challenge in challengeList"
              :key="challenge.id"
              cols="12"
              md="6"
              xl="4"
            >
              <v-sheet min-height="200">
                <v-lazy
                  v-model="challenge.isActive"
                  :options="{ threshold: .2 }"
                >
                  <ChallengeTile :challenge="challenge" />
                </v-lazy>
              </v-sheet>
            </v-col>
          </v-row>
          <v-btn
            v-if="nextPage"
            @click="loadNextPage"
          >
            load more
          </v-btn>
        </div>
        <v-alert
          v-else-if="errored"
          type="error"
        >
          Failed to fetch challenges.
        </v-alert>
        <div v-else>
          No challenges found.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ChallengeTile from '@/components/ChallengeTile.vue'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    ChallengeTile,
    Loading,
  },
  data: function() {
    return {
    }
  },
  computed: {
    ...mapState({
      loading: state => state.challenges.loading,
      errored: state => state.challenges.errored,
      nextPage: state => state.challenges.next,
    }),
    ...mapGetters({
      challengeList: 'challenges/active',
    }),
  },
  created: function () {
    this.$store.dispatch('challenges/getAll')
  },
  methods: {
    loadNextPage () {
      this.$store.dispatch('challenges/loadNextPage')
    },
  },
}
</script>
